$(document).ready(function () {
  $('.header_wrapper .search_block_wrap .search_btn').click(function () {
    $('.header_wrapper .search_block_wrap .search_form').fadeToggle(500);
  });
  $('.header_wrapper .search_form .bg_search').click(function () {
    $('.header_wrapper .search_block_wrap .search_form').fadeToggle(500);
  });

  $('.slider_front_wrapper .slider_front').slick({
    dots: true,
    slidesToShow: 1,
    slidesToScroll: 1,
  });
  $('.diagnostics-slider .slider_content').slick({
    dots: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false
  });
  $('.specialists_content').slick({
    slidesToShow: 4,
    slidesToScroll: 1,
    arrows: true,
    rows: 2,
    responsive: [{
      breakpoint: 1169,
      settings: {
        slidesToShow: 3,
        rows: 3,
        infinite: false
      }
    }, {
      breakpoint: 767,
      settings: {
        slidesToShow: 2,
        rows: 4,
        infinite: false,
        dots: true,
      }
    }]
  });
  $('.slide_big').slick({
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
    // autoplay: true,
    // autoplaySpeed: 4000,
    infinite: false,
    asNavFor: '.slide_min',
    responsive: [{
      breakpoint: 375,
      settings: {
        arrows: true,
      }
    }]
  });
  $('.slide_min').slick({
    slidesToShow: 11,
    slidesToScroll: 1,
    arrows: false,
    // autoplay: true,
    // autoplaySpeed: 4000,
    infinite: false,
    asNavFor: '.slide_big',
    focusOnSelect: true,
    responsive: [{
        breakpoint: 1169,
        settings: {
          slidesToShow: 5,
        }
      },
      {
        breakpoint: 375,
        settings: {
          slidesToShow: 3,
        }
      }
    ]
  });
  $('.slide_big .slick-track').lightGallery({
    selector: 'a',
    preload: 3,
    getCaptionFromTitleOrAlt: false,
  });
  $('.standart_conteiner').lightGallery({
    selector: 'a',
    preload: 3,
    getCaptionFromTitleOrAlt: false,
  });
  $('.scan_conteiner').lightGallery({
    selector: 'a',
    preload: 3,
    getCaptionFromTitleOrAlt: false,
  });

  $('.order_call_btn').on('click', function (e) {
    e.preventDefault();
    $.magnificPopup.open({
      items: {
        src: $('.block-webform-request_call')
      },
      type: 'inline',
      removalDelay: 400,
      mainClass: 'mfp-zoom-in',
      autoFocusLast: false
    })
  });
  $('.sign_up_btn').on('click', function (e) {
    e.preventDefault();
    $.magnificPopup.open({
      items: {
        src: $('.block-webform-sign_call')
      },
      type: 'inline',
      removalDelay: 400,
      mainClass: 'mfp-zoom-in',
      autoFocusLast: false
    })
  });
  $('.row_reviews').on('click', function (e) {
    e.preventDefault();
    $.magnificPopup.open({
      items: {
        src: $('.block-add-rewiew')
      },
      type: 'inline',
      removalDelay: 400,
      mainClass: 'mfp-zoom-in',
      autoFocusLast: false
    })
  });
  // $.fn.equivalent = function () {
  //   var $blocks = $(this),
  //     maxH = $blocks.eq(0).height();
  //   $blocks.each(function () {
  //     if ($(this).css('display') != 'block') return;
  //     console.log(this);
  //     maxH = ($(this).height() > maxH) ? $(this).height() : maxH;
  //   });
  //   $blocks.height(maxH);
  // }

  $.fn.equivalent = function () {
    var $blocks = $(this);
    $(this).find('li').hover(function () {
      var maxH = $blocks.eq(0).height();
      $blocks.find('ul').removeAttr('style').each(function () {
        if ($(this).parent().css('display') != 'block') return;
        maxH = ($(this).height() > maxH) ? $(this).height() : maxH;
      });
      $blocks.height(maxH);
    });
  }


  $('.menu_list.expanded').equivalent();

  $('.menu_list.expanded').hover(function () {
    $('.bg_menu').addClass('active');
    $(this).find('.list_block_menu').each(function () {
      $(this).equivalent();
    })

  }, function () {

    $('.bg_menu').removeClass('active');
  })
  // $('.first_menu > .menu_list.expanded').each(function () {
  //   $(this).find('.list_block_menu').equivalent();
  // });

  // $('.first_menu > .menu_list.expanded').eq(1).find('.list_block_menu').equivalent();
  // $('.list_block_menu').equivalent();

  // $('.menu_list.expanded').hover(function () {
  //   $('.bg_menu').addClass('active');
  //   // var a = $('#ttt').height();
  //   $(this).find('.list_block_menu').each(function () {
  //     console.log($(this));
  //     $(this).equivalent();
  //   })

  // }, function () {

  //   $('.bg_menu').removeClass('active');
  // })

  $(".edit-submitted-telefon").mask("+38(999) 999-9999");


  $('.tariff_title .arrow').on('click', function () {
    $(this).parent().next().toggleClass('active');
    $(this).toggleClass('active');
  })

  if ($('.menu-select .menu_item a').hasClass('active')) {
    console.log(this);
    $('.menu-select .menu_item a.active').parent().addClass('active');
  }
  $('.menu-select').on('click', function (e) {
    var $this = $(this);
    if ($this.hasClass('is-open')) {
      $this.removeClass('is-open');
      $(document).off('click.switch');
    } else {
      $this.addClass('is-open');
      var firstClick = true;
      $(document).on('click.switch', function (e) {
        if (!firstClick && $(e.target).closest('.menu-select').length == 0) {
          $this.removeClass('is-open');
          $(document).off('click.switch');
        }
        firstClick = false;
      });
    }
  });

  /*Mobile */
  var mobilenav = undefined;
  var mobileArrow = undefined;
  enquire.register("screen and (max-width: 1024px)", {
    match: function () {
      mobilenav = $('<div class="mobile-btn--menu mobile-btn--menu--nav"><span></span><span></span><span></span></div>');
      mobileArrow = $('<div class="arrow_menu"></div>');
      $('.header_wrapper').append(mobilenav);
      $('.manin_menu .second_menu').parent().prepend(mobileArrow);
      $('.manin_menu .third_menu').parent().prepend(mobileArrow);
      $('.manin_menu .fourth_menu').parent().prepend(mobileArrow);
      $('.manin_menu .fifth_menu ').parent().prepend(mobileArrow);
    },
    unmatch: function () {
      mobilenav.remove();
    }
  });

  $('.mobile-btn--menu').on('click', function () {
    $(this).toggleClass('open');
    $('.manin_menu').toggleClass('open');
  })

  $('.arrow_menu').on('click', function () {
    if ($(this).hasClass('active')) {

      $(this).removeClass('active');
      $(this).next().next().fadeOut();
    } else {
      $(this).next().next().fadeIn();
      $(this).addClass('active');
    }
  });
  /*End mobile */

  $(window).load(function () {
    var item = $('.middle .slider_front_wrapper .slick-dots');
    var formWidth = item.innerWidth() / 2;
    item.css({
      'margin-left': -formWidth
    });
  });
});